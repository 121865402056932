import React, { useEffect, useState } from 'react';
import axios from 'axios';
import style from './Home.module.css';
import Spinner from '../Spinner/Spinner';

function Home() {
  
  const [products, setProducts] = useState([]);
  const [datos, setDatos] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const querystring = require('querystring');

  products?.sort((a, b) => a.name.localeCompare(b.name));

  const CONSUMER_KEY = 'ck_6f1bd37351b3b7481f8f317e9c3333fb53fb1464';
  const CONSUMER_SECRET = 'cs_9359ccae6db4dd9627591d93d4f9009b53107cf4';
  const URL = 'https://www.tumejorsommier.com.ar/wp-json/wc/v3';
  const PER_PAGE = '100';
  const PAGES = ['1', '2'];

  const getProducts = async () => {
    try {
      for (let i = 0; i < PAGES.length; i++) {
        const res = await axios.get(`${URL}/products?per_page=${PER_PAGE}&page=${PAGES[i]}&consumer_key=${CONSUMER_KEY}&consumer_secret=${CONSUMER_SECRET}`);
        if (i === 0) {
          res.data.shift();
        }
        setProducts((prev) => [...prev, ...res.data]);
        setFilteredProducts((prev) => [...prev, ...res.data]); 
      }
      setLoading(false);
      } catch (error) {
          console.log(error);
      }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'datos') {
        let datos = event.data.payload;
        setDatos(datos);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    getProducts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    filterProducts(searchTerm);
  };

  const handleClick = (event, product) => {
    event.preventDefault();

    const URL = 'https://go.botmaker.com/api/v1.0/intent/trigger';
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'access-token': 'eyJhbGciOiJIUzUxMiJ9.eyJidXNpbmVzc0lkIjoicmVudGFzanVqdXkiLCJuYW1lIjoiZmVkZXJpY28gZGliIiwiYXBpIjp0cnVlLCJpZCI6Ing2bHpVSVFBVXNaZTVodEVyNkVQTkM2MkY2NjMiLCJleHAiOjE4NDMwNjg5MTcsImp0aSI6Ing2bHpVSVFBVXNaZTVodEVyNkVQTkM2MkY2NjMifQ.XayCjZ_U_9rOMWzVhbgFJNC4tnTmXR0a1dtBC2qOcNI5ZDmcIAJ_kptw8ueRVxKhwiljk6EiDj3021np2-MWzA'
    };

    let variables = {
      iFrameProductPrice: product.price,
      iFrameProductImage: product.images[0]?.src,
      iFrameProductName: product.name,
      iFrameProductPermalink: product.permalink
    };

    variables = JSON.stringify(variables);


    const body = querystring.stringify({
      chatPlatform: 'WEBCHAT',
      chatChannelId: datos.chatChannelId,
      platformContactId: datos.platformContactId,
      ruleNameOrId: 'iFramePush',
      variables: variables
    });

    axios.post(URL, body, { headers }).then((res) => {
      console.log('res: ', res);
    }).catch((err) => {
      console.log('err: ', err);
    });
  };

  const filterProducts = (searchTerm) => {
    const filtered = products.filter((product) => {
      const matchName = product.name.toLowerCase().includes(searchTerm.toLowerCase());
      const matchCategory = product.categories.some((category) =>
        category.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return matchName || matchCategory;
    });
    setFilteredProducts(filtered);
  };

  return (
    <>
      <div className={style.mainContainer} id='container'>
        <h1>Catálogo - TuMejorSommier</h1>
        <div className={style.searchBarContainer}>
        <input className={style.searchBar} type="text" value={searchTerm} onChange={handleSearch} placeholder="Buscar productos" />
        <span className="material-symbols-outlined">search</span>
        </div>
        
      </div>
      <div className={style.container}>
        {loading ? <Spinner /> : filteredProducts.map((product) => (
          <div className={style.itemContainer} key={product.id} onClick={(e) => handleClick(e, product)}>
        <div className={style.containerLeft}>
            <img src={product.images[0]?.src} alt={product.name} />
        </div>
        <div className={style.containerRight}>
            {/* <a href={product.permalink}> */}
              <h1>{product.name.toUpperCase()}</h1>
            {/* </a> */}
            {product.on_sale ? <p className={style.salePrice}><span className={style.price}>${product.regular_price}</span> <span className={style.salePrice}>${product.price}</span></p> : null}
            {/* <p className={product.on_sale ? style.offerPrice : style.price}>Precio: ${product.price},00</p> */}
          </div>
        </div>
        ))}
      </div>
    </>
  );
}

export default Home;